import React from "react";
import { Spinner } from "reactstrap";
import { useAuth } from "infrastructure/context/auth-context";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

const AuthenticatedApp = React.lazy(() => import("authenticated-app"));
const UnAuthenticatedApp = React.lazy(() => import("unAuthenticated-app"));

toast.configure();

const App = () => {
  const user = useAuth();

  if(user){
      Sentry.setUser({ id: user.userId.toString(), company_id: user.companyId })
  } else {
      Sentry.setUser(null)
  }

  return (
    <React.Suspense fallback={<Spinner color="info" />}>
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      {user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
