const GENIEBELT_API_URL = process.env.REACT_APP_GENIEBELT_API_URL!;
const APROPLAN_API_URL = process.env.REACT_APP_APROPLAN_API_URL!;
const APROPLAN_API_VERSION = process.env.REACT_APP_APROPLAN_API_VERSION!;
const APROPLAN_REQUESTER_ID = process.env.REACT_APP_APROPLAN_REQUESTER_ID!;
const GAC_INTEGRATION_URL = process.env.REACT_APP_GAC_URL!;
const SALESFORCE_PROVIVIENDA_URL = process.env.REACT_APP_SALESFORCE_PROVIVIENDA!;

const SALESFORCE_PROVIVIENDA_USER_EMAIL = 'integrations+sf_provivienda@letsbuild.com'
const SALESFORCE_PROVIVIENDA_USER_TEAM = 'LetsBuild Integrations'
const ENVIRONMENT = process.env.REACT_APP_ENV!;

export {
  GENIEBELT_API_URL,
  APROPLAN_API_URL,
  APROPLAN_API_VERSION,
  APROPLAN_REQUESTER_ID,
  GAC_INTEGRATION_URL,
  SALESFORCE_PROVIVIENDA_URL,
  SALESFORCE_PROVIVIENDA_USER_EMAIL,
  SALESFORCE_PROVIVIENDA_USER_TEAM,
  ENVIRONMENT
};
