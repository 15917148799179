import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "app";
import AppProvider from "infrastructure/context";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ENVIRONMENT } from './infrastructure/environment';


Sentry.init({
    dsn: "https://c394d549e2f442fcb98a93e96fbe8cd8@sentry.shared1.eu-west-1.aws.shared.lb4.co/9",
    integrations: [new BrowserTracing()],
    environment: ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
