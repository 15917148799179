import React from "react";
import { parse } from "query-string";

export type User = {
  userId: number;
  token: string;
  companyId: number;
};

const AuthContext = React.createContext<User | null | undefined>(undefined);

function AuthProvider(props: object) {
  let user: User | null = null;

  const temp = window.sessionStorage.getItem("AUTH");

  if (temp) {
    user = JSON.parse(temp);
  }

  if (!user) {
    const result = readUserFromParams();
    if (result) {
      user = result;
      window.sessionStorage.setItem("AUTH", JSON.stringify(user));
    }
  }

  return <AuthContext.Provider value={user} {...props} />;
}

function readUserFromParams(): User | undefined {
  let { userid, token, companyid } = parse(window.location.search);
  if (!userid || userid instanceof Array) {
    return undefined;
  }

  if (!token || token instanceof Array) {
    return undefined;
  }

  if (!companyid || companyid instanceof Array) {
    return undefined;
  }

  return {
    userId: parseInt(userid as string, 10),
    token: `Basic ${token}`,
    companyId: parseInt(companyid as string, 10)
  };
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
